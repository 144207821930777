.webcam-container {
  --shutter-size: 50px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;

  button {
    // reset
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: none;
    outline: none;
  }
  button.back {
    position: fixed;
    top: 1rem;
    left: 0.5rem;
    color: #fff;
    width: 30px;
    height: 30px;
  }
  button.shutter,
  button.shutter::before {
    --half-compensation: calc(calc(var(--shutter-size) / 2) * -1);
    position: absolute;
    width: var(--shutter-size);
    height: var(--shutter-size);
    transform: translateX(var(--half-compensation));
    left: 50%;
    bottom: 20px;
    border: 0;
    border-radius: 100%;
    background-color: #fff;
  }
  button.shutter::before {
    content: '';
    z-index: -1;
    border: 2px solid white;
    background-color: transparent;
    width: calc(var(--shutter-size) + 10px);
    height: calc(var(--shutter-size) + 10px);
    transform: translateX(calc(var(--half-compensation) - 5px))
      translateY(calc(var(--shutter-size) / 2));
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    max-width: 100vw;
    max-height: 100vh;
  }
}
