.django-form-modal {
  .modal {
    z-index: 1035;
  }
  .modal-backdrop {
    z-index: 1030;
  }
}
@media screen and (max-width: 768px) {
  .form-check.checkbox {
    flex-direction: column !important;
  }
}
