.add-file-card {
  cursor: pointer;
  height: 100px;
  width: 200px;
  min-width: 200px;
  border-radius: 5px;
  border: 1px solid var(--primary);
  margin-right: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: var(--primary);
    font-size: 16px;

    &:first-of-type {
      font-size: 40px;
      line-height: 40px;
      font-weight: bold;
    }
  }
}

.file-card {
  cursor: pointer;
  z-index: 1;

  height: 100px;
  width: 200px;
  min-width: 200px;
  background-color: #fff !important;
  border-radius: 5px;
  border: 1px solid lightgray;
  position: relative;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin-right: 0.3rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000040;
  }

  .extension-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 22px;
    opacity: 0.5;
    color: black;
  }

  .download-wrapper {
    position: absolute;
    top: 0.5rem;
    right: 0.3rem;
    z-index: 9;

    .download-icon {
      background-color: #00000030;
      border-radius: 5px;
      padding: 0.3rem 0.5rem;
      color: #fff;
      margin-left: 0.3rem;
    }
  }

  .name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000040;
    color: #fff;
    padding: 0.5rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;

    small {
      display: block;
      font-size: 11px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }
  }
}
