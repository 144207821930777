.django-list {
  @media screen and (max-width: 768px) {
    tbody {
      position: relative;
      tr {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        max-width: calc(100vw - 70px);

        td {
          border-color: #ddd;
          width: 100% !important;

          &:first-child {
            border-top: 0;
          }
        }
      }
    }
  }
}
