// Here you can add other styles

.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: 1100;
}

.select-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .value-input {
    width: 80%;
    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .factor-select {
    width: 20%;
    padding-left: 0.5rem;
    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .action {
    transition: 0.05s;
    margin-left: 0.5rem;
    text-align: center;
    color: #999;

    &:hover {
      transition: 0.1s;
      color: #321fdb;
    }
  }
}

.user-title {
  padding-left: 10px;
  flex: 1;
  flex-direction: column;
  display: flex;
  line-height: 1.2;
}

.color-dot {
  &:before {
    content: '';
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    background-color: var(--color);
    border-radius: 4px;
    margin-right: 0.3rem;
  }
}

.select__basic-single {
  width: 100%;
}

.company-select-item {
  cursor: pointer;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 0.3rem;
  margin: 0.2rem 0;
  max-width: calc(100vw - 250px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 80px;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
  span {
    margin: 0 0.5rem;
  }

  &.selected {
    border: 2px solid var(--primary);
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.no-margin {
  margin: 0 !important;
}
.margin-auto {
  margin: 0 auto;
}
.no-border {
  border: 0 !important;
}
.muted {
  font-size: 0.8em;
  color: #888;
  margin: 0 0.3rem;
}

td {
  vertical-align: middle !important;
}

@media screen and (max-width: 768px) {
  #main-header {
    justify-content: space-between;
  }
}

.table-fields-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transition: transform 0.5s ease-in-out;
  }

  .chevron-open {
    transform: rotate(180deg);
  }
}

.dropdown-open {
  max-height: 20rem !important;
}

.selector-dropdown {
  background: white;
  z-index: 999;
  max-height: 0;
  height: fit-content;
  transition: max-height 0.5s ease-in-out;
  position: absolute;
  top: 35px;
  left: 1rem;
  right: 1rem;
  box-shadow: 0 0 3px grey;
  border-radius: 0.25rem;
  overflow-y: auto;

  .dropdown-row {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    span {
      min-width: 2rem;
    }
    .column-title {
      padding-left: 0.5rem;
    }
  }
}

button.btn {
  svg.c-icon {
    margin: 0 !important;
    margin-top: -0.15rem !important;
    margin-right: 0.1rem !important;
    height: 1rem !important;
  }
}

.hidden {
  display: hidden;
}

.qrcode-print-container {
  display: flex;
  justify-content: center;
}

.mb1 {
  margin-bottom: 1rem;
}

.form-external {
  margin-top: 2rem;
  .form-mb {
    margin-bottom: 10px;
  }
}

.external-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  box-shadow: 0 0 5px grey;
  img {
    height: 3rem;
  }
}

.external-header-compensation {
  width: 100%;
  height: 4rem;
}

.profile-page-cards {
  margin: 0 auto;
  @include media-breakpoint-up(sm) {
    width: 60%;
  }

  .plan-card {
    padding: 0 !important;
    b {
      color: black;
    }
  }

  h5 {
    font-weight: bold;
  }

  .card {
    padding: 1rem;

    .company-info {
      display: flex;
      flex-direction: row;

      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 1rem;
      }

      img {
        height: 3.5rem;
        width: 3.5rem;
      }
    }
  }
}

.qr-reader-beeping {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  top: 0;
}

@media (max-width: 768px) {
  .c-main {
    padding-top: 1rem;
  }
  .toast-container {
    top: auto;
    bottom: 0 !important;
    max-width: 100vw !important;
    .react-toast-notifications__toast {
      max-width: 100% !important;
    }
  }
}

@media print {
  .hidden-print {
    display: none;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: #fff;
  }

  body .page {
    margin: 0;
    border: 0;
    max-width: unset;
    min-width: unset;
    max-height: unset;
    min-height: unset;
    break-after: always;
    padding: 0;
  }

  @page {
    margin: 0pt;
    orientation: landscape;
  }
}

.demo-editor {
  height: 275px;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}

.demo-wrapper {
  width: 100%;
  display: block;
  margin-bottom: 25px;
}
.c-app,
.c-wrapper {
  min-height: calc(100vh - 21px) !important;
}

pre {
  padding: 1rem;
  background-color: #eee;
  border: 1px solid lightgrey;
  border-radius: 4px;
  line-break: anywhere;
  color: #000;
  overflow: hidden;
  white-space: pre-wrap;
}

.MuiTimeline-root {
  padding: 0;
}
.MuiTimelineItem-root {
  &::before {
    max-width: 0 !important;
    padding: 0 !important;
    content: none !important;
  }

  i {
    display: inline-block;
    margin-bottom: 0.7rem !important;
  }
}
